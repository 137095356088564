import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'

import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const downloads = () => {
  const data = useStaticQuery(graphql`
    query DownloadsSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Downloads" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Home Lenses"
      />
      <section className="pt-5 mt-5 pb-5">
        <div className="container-1">
          <div className="row mt-3">
            <div className="col-md-8 pt-0">
              <p className="title breadcrumb-text pt-4">
                <a
                  href="/architecture-and-integrations/"
                  className="title_text"
                >
                  PRODUCT
                </a>{' '}
                /<span className="title_text font-weight-bold">DOWNLOADS</span>
              </p>
              <h1>Downloads</h1>
              <p className="hero-description-dark">
                Ready to kick the tires? We make it easy!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="se-section">
        <div className="container-1 pb-5">
          <div className="row mt-4">
            <div className="col-md-8">
              <div className="shadow bg-white p-5">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="paragraph-title pt-3">Lenses Box</h2>
                  </div>
                  <div className="col-md-6">
                    <p className="mb-5">
                      All-in-one <br />
                      Docker for Kafka Development
                    </p>
                    <p className="pb-0 mb-0 f-16 mt-4">
                      <b>FREE for ever!</b>
                    </p>
                    <StaticImage
                      className="text-center w-65"
                      src="../resources/images/logos/lensesio-box-red.png"
                      placeholder="tracedSVG"
                      alt="Lenses.io lenses-box"
                    />
                  </div>
                  <div className="col-md-6">
                    <ul className="f-16">
                      <li className="downloads-box-list">
                        <b>Perfect</b> for local development
                      </li>
                      <li className="downloads-box-list">
                        <b>Intuitive </b> Lenses UI
                      </li>
                      <li className="downloads-box-list">
                        <b>Single </b> Kafka Broker
                      </li>
                      <li className="downloads-box-list">
                        <b>Simple</b> setup
                      </li>
                      <li className="downloads-box-list">
                        <b>Live</b> examples
                      </li>
                      <li className="downloads-box-list">
                        <b>Free</b> up to 25M records
                      </li>
                    </ul>

                    <br />
                    <div className="roll-button">
                      <a
                        className="text-white blue-bg"
                        href="/downloads/lenses/"
                        id="downloads-lenses-box"
                      >
                        {' '}
                        <i className="fa fa-cloud-download mr-4"></i>
                        <span data-hover="Pull docker">Pull docker</span>
                      </a>
                    </div>
                  </div>
                </div>

                <hr className="my-4" />
              </div>
            </div>
            <div className="col-md-4 text-center p-5 d-none d-md-block">
              <span className="paragraph-title">
                Trusted by 10000+ developers
              </span>

              <div className="row mt-4">
                <div className="col-md-4 downloads-trusted-by-img pt-1">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/zopa.png"
                    placeholder="tracedSVG"
                    alt="Zopa"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/generali.png"
                    placeholder="tracedSVG"
                    alt="Generali"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/its.png"
                    placeholder="tracedSVG"
                    alt="its"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-75"
                    src="../resources/images/clients/grid/sas.png"
                    placeholder="tracedSVG"
                    alt="SAS"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/accenture.png"
                    placeholder="tracedSVG"
                    alt="Accenture"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/euro.png"
                    placeholder="tracedSVG"
                    alt="Euro"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/sainsburys.png"
                    placeholder="tracedSVG"
                    alt="Sainsbury Argos"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/daimler.png"
                    placeholder="tracedSVG"
                    alt="daimler logo"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/playtika.png"
                    placeholder="tracedSVG"
                    alt="playtika logo"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/komatsu.png"
                    placeholder="tracedSVG"
                    alt="lomatsu logo"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/adidas.png"
                    placeholder="tracedSVG"
                    alt="adidas logo"
                  />
                </div>
                <div className="col-md-4 downloads-trusted-by-img pt-2">
                  <StaticImage
                    className="w-100"
                    src="../resources/images/clients/grid/babylon.png"
                    placeholder="tracedSVG"
                    alt="babylon logo"
                  />
                </div>
                <div className="col-md-12 text-center mt-3">
                  <a className="link-text" href="/customers/">
                    VIEW ALL
                  </a>
                </div>
              </div>

              <hr className="mt-3 mb-3" />
              <span className="mt-2 mb-0 pb-0 pt-3 fw-700">powered by</span>
              <div className="power-icons mt-3">
                <div className="row justify-content-center">
                  <StaticImage
                    className="downloads-powered-by-img-1"
                    src="../resources/images/homepage/kafka-logo.png"
                    placeholder="tracedSVG"
                    alt="kafka logo"
                  />
                  <div className="downloads-powered-by-img-2">
                    <StaticImage
                      src="../resources/images/homepage/kubernetes-logo.png"
                      placeholder="tracedSVG"
                      alt="kubernetes logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-8">
              <div className="row pl-5">
                <div className="col-md-4 mt-5 col-12">
                  <h2 className="card-title paragraph-title p-0">
                    Get a Live Demo
                  </h2>
                  <p className="card-text">
                    Schedule a one-on-one live technical demo of Lenses.
                  </p>
                  <a href="/contact/lenses-demo/" className=" primary-text">
                    Request now <i className="red-pointer-right"></i>
                  </a>
                </div>
                <div className="col-md-4 mt-5 offset-md-2 col-12">
                  <h2 className="card-title paragraph-title p-0">
                    Lenses Enterprise
                  </h2>
                  <p className="card-text">
                    Want to connect to your cluster or try on cloud?
                  </p>
                  <a
                    href="/downloads/lenses-enterprise/?s=other"
                    className=" primary-text"
                  >
                    Start a Free Trial <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="se-section bg-light">
        <div className="container-1 pt-5 pb-5">
          <div className="row mt-4">
            <div className="col-md-12 text-center">
              <h2 className="paragraph-title mb-3">Lenses on Cloud</h2>
              <p className="pb-5">
                {' '}
                Lenses is compatible with your Cloud setup and Kafka Managed
                Services. <br />
                Select cloud provider
              </p>
            </div>
            <div className="col-md-3 text-center ">
              <div className="downloads-on-cloud-img mx-auto">
                <StaticImage
                  src="../resources/images/logos-clouds/amazon-web-services.svg"
                  placeholder="tracedSVG"
                  alt="lenses on amazon"
                />
              </div>
              <p className="f-18 mt-3">
                <a
                  className="link-text"
                  href="/cloud/aws-msk/"
                  id="downloads-cloud-amazon"
                >
                  Amazon MSK
                </a>
              </p>
              <hr className="d-none d-md-block" />
              <p className="f-18 mt-3">
                <a
                  className="link-text"
                  href="https://aws.amazon.com/marketplace/pp/B07Q88VPTW"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="downloads-cloud-amazon-marketplace"
                >
                  Amazon Marketplace
                </a>
              </p>
              <hr className="d-md-none d-lg-none d-xl-none" />
            </div>
            <div className="col-md-3 text-center mobile-margin-top">
              <div className="downloads-on-cloud-img mx-auto">
                <StaticImage
                  src="../resources/images/logos-clouds/azure.svg"
                  placeholder="tracedSVG"
                  alt="lenses on azure"
                />
              </div>
              <p className="f-18 mt-3">
                <a
                  className="link-text"
                  href="/cloud/azure/"
                  id="downloads-cloud-azure"
                >
                  Azure HDInsight
                </a>
              </p>
              <hr className="d-none d-md-block" />
              <p className="f-18 mt-3">
                <a
                  className="link-text"
                  href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/lensesioltd.lenses-io"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="downloads-cloud-azure-marketplace"
                >
                  Azure Marketplace{' '}
                </a>
              </p>
              <hr className="d-md-none d-lg-none d-xl-none" />
            </div>
            <div className="col-md-3 text-center mobile-margin-top">
              <div className="downloads-on-cloud-img mx-auto">
                <StaticImage
                  src="../resources/images/logos-clouds/google-cloud.svg"
                  placeholder="tracedSVG"
                  alt="lenses on google cloud"
                />
              </div>
              <p className="f-18 mt-3">
                <a
                  className="link-text"
                  href="/cloud/google-cloud/"
                  id="downloads-cloud-googlecloud"
                >
                  Google Cloud
                </a>
              </p>
              <hr className="d-none d-md-block" />
              <p className="f-18 mt-3">
                Google Marketplace <sup className="f-13">SOON</sup>
              </p>
              <hr className="d-md-none d-lg-none d-xl-none" />
            </div>
            <div className="col-md-3 text-center mobile-margin-top">
              <i className="fa fa-cloud cloud-blue f-55" aria-hidden="true"></i>
              <p className="f-18">
                <a
                  className="link-text"
                  href="https://docs.lenses.io/4.3/installation/cloud/aiven/"
                >
                  Other Managed Clouds
                </a>
              </p>
              <hr />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 text-center">
              <div className="mb-4">Need help with Clouds? Let us help.</div>
              <a
                className="primary-text"
                href="/contact-us/"
                id="downloads-cloud-contactus"
              >
                Contact <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 mt-5 pb-5">
        <div className="container-1">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="paragraph-title mb-2">Lenses Add-Ons</h2>
              <p className="pb-5">
                {' '}
                Lenses comes with a rich ecosystem to help you with specific
                needs
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3 text-center downloads-add-ons-border-bottom-right">
                  <h3 className="text-center f-16">Lenses CLI</h3>
                  <p className="downloads-add-ons-box-text">
                    The Command Line Interface for Lenses supports all features
                    to help you streamline your CI/CD automation
                  </p>
                  <a
                    href="https://archive.lenses.io/tools/lenses-cli/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-cli"
                  >
                    Download
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-bottom-right">
                  <h3 className="text-center f-16">Prometheus/Grafana</h3>
                  <p className="downloads-add-ons-box-text">
                    Operational Kafka Monitoring provides curated templates and
                    ready to use exporters for Prometheus & Grafana{' '}
                  </p>
                  <a
                    href="https://secure.lenses.io"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-prometheus"
                  >
                    Clients Only
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-bottom-right">
                  <h3 className="text-center f-16">Lenses Connectors</h3>
                  <p className="downloads-add-ons-box-text">
                    Find out our Open Source Apache 2.0 licensed connectors to
                    move data in and out of Kafka.
                  </p>
                  <a
                    href="/kafka-connectors/"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-connectors"
                  >
                    Find Connectors
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-bottom">
                  <h3 className="text-center f-16">Topology Integration</h3>
                  <p className="downloads-add-ons-box-text">
                    Connect your custom producers & consumers, streaming
                    applications like KStreams, Spark or any other Microservices
                    to Lenses{' '}
                  </p>
                  <a
                    href="https://docs.lenses.io/4.3/tools/clients/external-apps/"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-topology"
                  >
                    Add to Maven
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-bottom-right">
                  <h3 className="text-center f-16">Lenses Archives</h3>
                  <p className="downloads-add-ons-box-text">
                    Get the latest archives of Lenses for a manual Linux setup.{' '}
                    <br />
                    <a
                      className="link-text"
                      href="https://docs.lenses.io/4.3/installation/linux/"
                    >
                      Learn more
                    </a>
                  </p>
                  <a
                    href="https://archive.lenses.io/lenses/"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-archives"
                  >
                    Download
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-bottom-right">
                  <h3 className="text-center f-16">Lenses Docker</h3>
                  <p className="downloads-add-ons-box-text">
                    Deploy Lenses as Docker container.{' '}
                    <a
                      className="link-text"
                      href="https://docs.lenses.io/4.3/installation/docker/"
                    >
                      Learn More
                    </a>{' '}
                    or simply Docker Pull!{' '}
                  </p>
                  <a
                    href="https://hub.docker.com/u/lensesio"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-dockers"
                  >
                    Docker Hub
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-bottom-right">
                  <h3 className="text-center f-16">Lenses Helm</h3>
                  <p className="downloads-add-ons-box-text">
                    Helm, a package manager for Kubernetes, is the recommended
                    way to install Lenses. <br />
                    <a
                      className="link-text"
                      href="https://docs.lenses.io/4.3/installation/kubernetes/"
                    >
                      Read the docs.
                    </a>
                  </p>
                  <a
                    href="https://github.com/lensesio/lenses-helm-charts"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-helm"
                  >
                    Download
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-bottom">
                  <h3 className="text-center f-16">Lenses SQL Runners</h3>
                  <p className="downloads-add-ons-box-text">
                    Enables scalable{' '}
                    <a
                      className="link-text"
                      href="https://docs.lenses.io/4.3/sql/streaming/"
                    >
                      streaming SQL queries
                    </a>{' '}
                    over Kafka on a single click. Integrates with Kubernetes or
                    VM setups.{' '}
                    <a
                      className="link-text"
                      href="https://docs.lenses.io/4.3/sql/streaming/"
                    >
                      Learn More
                    </a>{' '}
                  </p>
                  <a
                    href="https://secure.lenses.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-sm btn-prim-outline f-14 disabled"
                    id="downloads-addons-sqlrunners"
                  >
                    Clients Only
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-right">
                  <h3 className="text-center f-16">WS JS Client</h3>
                  <p className="mt-3 mb-3">
                    {' '}
                    <a
                      className="link-text"
                      href="https://docs.lenses.io/3.2/dev/redux-lib/index.html"
                    >
                      Documentation
                    </a>{' '}
                  </p>
                  <a
                    href="//www.npmjs.com/package/redux-lenses-streaming"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-jsclient"
                  >
                    Download
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-right">
                  <h3 className="text-center f-16">Python Client</h3>
                  <p className="mt-3 mb-3">
                    <a
                      className="link-text"
                      href="https://docs.lenses.io/4.3/tools/clients/python/"
                    >
                      Documentation
                    </a>{' '}
                  </p>
                  <a
                    href="//pypi.org/project/lenses_python/"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-python"
                  >
                    Download
                  </a>
                </div>

                <div className="col-md-3 text-center downloads-add-ons-border-right">
                  <h3 className="text-center f-16">JDBC Client</h3>
                  <p className="mt-3 mb-3">
                    <a
                      className="link-text"
                      href="https://docs.lenses.io/4.3/tools/clients/"
                    >
                      Documentation
                    </a>{' '}
                  </p>
                  <a
                    href="https://archive.lenses.io/tools/lenses-jdbc/"
                    className="btn-sm btn-prim-outline f-14"
                    id="downloads-addons-jdbc"
                  >
                    Download
                  </a>
                </div>

                {/* <div className="col-md-3 text-center p-4">
            <h3 className="text-center f-16 pb-4">Fast Data on Cloudera</h3>
            <a href="/product/kafka-on-cloudera/" className="btn-sm btn-prim-outline f-14"
              id="downloads-addons-cloudera">LEARN MORE</a>
          </div> */}
              </div>
            </div>
          </div>
          <br />
        </div>
      </section>

      <section className="se-section pt-5 pb-5">
        <div className="container-1 pt-5">
          <div className="row pb-5">
            <div className="col-md-12 text-center">
              <h2 className="paragraph-title mb-4">
                Frequently Asked Questions
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 offset-md-2">
              {/* <!-- FAQ-1 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq1"
                        className="faq-link"
                      >
                        What is the difference of Lenses Box and Lenses
                        Enterprise?
                      </a>
                    </div>
                  </div>
                  <div id="faq1" className="collapse">
                    <div className="card-body ">
                      <p>
                        <a href="/downloads/lenses/">Lenses Box</a> is a single
                        Docker container setup optimized for Kafka Development
                        environments. Contains a single broker installation of
                        Kafka, including the required services, open source
                        connectors and of course Lenses and Lenses CLI.
                        It&apos;s perfect for CI/CD, cloud deployments or even{' '}
                        <a href="https://docs.lenses.io/4.3/tools/box/">
                          {' '}
                          new starters
                        </a>
                        !
                      </p>
                      <p>
                        <a href="/downloads/lenses-enterprise/?s=other">
                          Lenses Enterprise
                        </a>
                        , does not contain or is thight to any Kafka setup. You
                        can configure it to <b>connect to your own cluster</b>.
                        Lenses is compatible with all popular distributions of
                        Kafka like: vanilla Apache Kafka, Confluent, AWS, Azure
                        HDInsight, Aiven, Cloudera, Hortonworks etc.
                      </p>
                      <p>
                        Lenses has simplified the deployment in cloud providers
                        using its own provisioning and management cloud
                        templates tailored to the particular cloud.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-2 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq2"
                        className="faq-link"
                      >
                        Is Lenses Box Free?
                      </a>
                    </div>
                  </div>
                  <div id="faq2" className="collapse">
                    <div className="card-body">
                      <p>Lenses Box is Free for ALL and forever.</p>
                      <p>
                        In order to use the Docker of Lenses Box you require a
                        security (EULA) key. You may need to refresh your key
                        from time to time for security reasons.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-3 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq3"
                        className="faq-link"
                      >
                        Can I use Lenses Box in Production?
                      </a>
                    </div>
                  </div>
                  <div id="faq3" className="collapse">
                    <div className="card-body">
                      <p>
                        Lenses is a Docker container that includes all required
                        services for a Kafka Setup. The setup contains one
                        instance of each service for example 1 Kafka broker, 1
                        Connect worker etc. For production environments it is
                        recommended to have a multi-node setup for scalability
                        and fail-over use cases. Also, Lenses Box allows up to
                        25M records on the cluster. However, if you want to use
                        Lenses Box with over 25M records or for a production
                        deployment you will require a License.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-4 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq4"
                        className="faq-link"
                      >
                        How can I connect Lenses Box to my Kafka Cluster?
                      </a>
                    </div>
                  </div>
                  <div id="faq4" className="collapse">
                    <div className="card-body">
                      <p>
                        Lenses Box is a Docker container which includes a single
                        kafka broker setup. If you want to try out Lenses with
                        your own cluster you will require Lenses Enterprise.
                      </p>
                      <p>
                        Lenses Enterprise is very simple to setup, requires
                        minimal resources, and you have various ways to deploy
                        on cloud or on premise: Linux VMs, Docker, Kubernetes /
                        Helm, Cloud providers etc
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ-5 --> */}
              <div className="card-group mb-3">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title mb-0">
                      <a
                        data-toggle="collapse"
                        href="#faq5"
                        className="faq-link"
                      >
                        Is Lenses compatible with Kafka cloud managed services?
                      </a>
                    </div>
                  </div>
                  <div id="faq5" className="collapse">
                    <div className="card-body">
                      <p>
                        Yes. Lenses works with AWS MSK, Azure HDInside, Aiven,
                        Confluent Cloud and other managed cloud services.
                      </p>
                      <p>
                        Lenses also provides deployment templates tailored made
                        for each Cloud provider.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-5 pb-5">
            <div className="col-md-12 text-center">
              More questions? Let us help.
              <br />
              <a
                className="primary-text"
                href="/contact-us/"
                id="downloads-faq-contactus"
              >
                Contact <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default downloads
